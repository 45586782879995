import "./index.css";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { getdata } from "../../../services";
import PageLayout from "../../../layouts/PageLayout";

import {
  setDepartmentDetail,
  setHeadCount,
  getPreviousAndCurrentShift,
  activedatefilter,
  setPeriodType,
  setRealtimePayPeriod
} from "../../../store/actions/Compensate";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import ContentLoader from "react-content-loader";
import { SocketContext } from "../../../socket";
import "font-awesome/css/font-awesome.min.css";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import Select from "react-select";
import Button from "@atlaskit/button";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../../providers/ThemeProvider";
import green from "../../../assets/img/polygon9.png";



const MyLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader
      viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

const GraphLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader
      viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="45" />
    </ContentLoader>
  </div>
);

function RealtimeTrend(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(null);



  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

   const toggleOpenTwo = () => {
     setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
   };

  const onSelectChange = (selectedValue) => {
    toggleOpen();
    setDropdownValue(selectedValue);
  };
  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };
  const options1 = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  const options2 = [
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Pay Period", label: "Pay Period" },
    { value: "Month", label: "Month" },
    { value: "Quarter", label: "Quarter" },
  ];


  const [themeMode, setTheme] = useContext(ThemeContext);
  var options = {
    chart: {
      height: 90,
      type: "line",

      dropShadow: {
        enabled: false,
        color: "#0ca788",
        top: 1,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },



    // colors: [color],
    colors: ["#0ca788", "#247BA0"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      //  curve: "smooth",
      width: 3,
    },
    grid: {
      borderColor: themeMode.theme === "DARK" ? "#FFF" : "#DDD",
      row: {
        colors: [themeMode.theme === "DARK" ? "#555" : "#f3f3f3", "transparent"],
        opacity: 0.0,
      },
      padding: {
        left: 12,
        right: 15,
      },
    },
    markers: {
      size: 7,
      hover: {
        size: 10,
        sizeOffset: 3,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
    xaxis: {
      categories: ['11/13 -03:00', '11/13 -07:00', '11/13 -11:00', '11/13 -15:00', '11/13 -23:00', '11/14 -03:00', '11/14 -07:00', '11/14 -11:00', '11/14 -15:00', '11/14 -23:00', '11/15 -03:00', '11/15 -07:00', '11/15 -11:00', '11/15 -15:00', '11/15 -23:00',],
      color: themeMode.theme === "DARK" ? "#FFF" : "black",
      tickPlacement: 'between',
      title: {
        show: false,
      },

      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
          fontSize: '10px',
          // offsetX: -50,
          // offsetY: -30,
        },

        // offsetX: 5,
        // rotate: -45,

      }
    },
    yaxis: {
      show: true,
      color: themeMode.theme === "DARK" ? "#FFF" : "black",
      tickAmount: 5,
      //  min: 0,
      //max: 100
      labels: {
        show: true,

        style: {
          colors: themeMode.theme === "DARK" ? "#FFF" : "black",
          fontSize: '11px',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-label',
        },

      },
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: 25,
      offsetX: 5,
      show: false,
    },
    // ====================annotations===========================

    annotations: {
      yaxis: [
        {
          y: 70,
          borderColor: '#0ca788',
          borderWidth: 2,
          label: {
            borderColor: '#0ca788',
            textAnchor: 'start',
            position: 'left',
            style: {
              color: '#fff',
              background: '#0ca788'
            },
            text: 'Average Available Beds'
          }
        }
      ]
    }
  };

  return (
    <div className="newrealtime">
      <PageLayout>
        <Row className="align-items-center my-3">
          <Col md={{ span: 3 }}>
            <div className="d-flex justify-content-start align-items-center">
              <Link
                // to={`/activedailymanagement/schedule-summary`}

                className="f-14 white-text-dark-mode"
              >
                <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
              </Link>
            </div>
          </Col>
          <Col md={{ span: 6 }}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="ht">
                <h3 className="text-center color-black m-0">Trends</h3>
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue
                        ? dropdownValue.label
                        : "100061102 - 4 WEST"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: null,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={onSelectChange}
                    // options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                    options={options1}
                  />
                </CustomDropdown>
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : "From Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: null,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={onSelectChange}
                    // options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                    options={options1}
                  />
                </CustomDropdown>
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : "End Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: null,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={onSelectChange}
                    // options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                    options={options}
                  />
                </CustomDropdown>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <div className="custom-select-v">
                  <CustomDropdown
                    isDropdownOpen={isDropdownOpentwo}
                    onClose={toggleOpenTwo}
                    target={
                      <Button
                        iconAfter={<ChevronDown />}
                        onClick={toggleOpenTwo}
                        isSelected={isDropdownOpentwo}
                      >
                        {dropdownValue ? dropdownValue.label : "Time Period"}
                      </Button>
                    }
                  >
                    <Select
                      autoFocus
                      backspaceRemovesValue={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                      }}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      isClearable={false}
                      menuIsOpen={isDropdownOpentwo}
                      onChange={(e) => {
                        toggleOpenTwo();
                        setDropdownValueTwo(e);
                      }}
                      // options={costCenterList}
                      placeholder="Search..."
                      styles={selectStyles}
                      tabSelectsValue={false}
                      value={dropdownValue}
                      options={options2}
                    />
                  </CustomDropdown>
                </div>
              </div>
            </div>
          </Col>
          <Col md={{ span: 3 }}></Col>
        </Row>

        <Row className="height-full-screen gx-2 gy-2">
          <Col md={{ span: 6 }} className="h-50">
            <div class="height-3 h-100">
              <div class="white-bg shadow-box pb-0 h-25p h-100">
                <div class="box-heading text-center mb-2">
                  4 West - RN Available Hours
                </div>
                <div class="d-flex justify-content-center mobile-wrap ">
                  <div class=" h-100 bg-white d-flex align-items-center me-5 mobile-margin-0 mobile-w-100 mobile-mb-10">
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2 h-85 px-5 mobile-w-100 mobile-text-center">
                      <h4 class="f-14 w-100 m-0">
                        13.7 - Average Available Beds
                      </h4>
                    </div>
                  </div>
                  <div
                    class=" h-100 bg-white d-flex align-items-center"
                    style={{ maxWidth: "350px" }}
                  >
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2">
                      <p class="f-14 text-center w-100 m-0">
                        Available Beds is up 66%
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <img
                          src={green}
                          height="15"
                          style={{ verticalAlign: "text-top" }}
                        />{" "}
                        &nbsp; 6.8 Average Daily Available Beds
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <small>Compared to Previous Pay Period</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="mobile-w-600 h-100 h-100-v "
                  style={{ width: "100%" }}
                >
                  <Chart
                    options={options}
                    series={[
                      {
                        name: "Available Beds",
                        data: [
                          10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 41, 35, 51,
                          49, 62,
                        ],
                      },
                    ]}
                    type="line"
                    //  width={"100%"}
                    height={230}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={{ span: 6 }} className="h-50">
            <div class="height-3 h-100">
              <div class="white-bg shadow-box pb-0 h-25p h-100">
                <div class="box-heading text-center mb-2">
                  4 West - RN Available Hours
                </div>
                <div class="d-flex justify-content-center mobile-wrap ">
                  <div class=" h-100 bg-white d-flex align-items-center me-5 mobile-margin-0 mobile-w-100 mobile-mb-10">
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2 h-85 px-5 mobile-w-100 mobile-text-center">
                      <h4 class="f-14 w-100 m-0">
                        13.7 - Average Available Beds
                      </h4>
                    </div>
                  </div>
                  <div
                    class=" h-100 bg-white d-flex align-items-center"
                    style={{ maxWidth: "350px" }}
                  >
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2">
                      <p class="f-14 text-center w-100 m-0">
                        Available Beds is up 66%
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <img
                          src={green}
                          height="15"
                          style={{ verticalAlign: "text-top" }}
                        />{" "}
                        &nbsp; 6.8 Average Daily Available Beds
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <small>Compared to Previous Pay Period</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="mobile-w-600 h-100 h-100-v "
                  style={{ width: "100%" }}
                >
                  <Chart
                    options={options}
                    series={[
                      {
                        name: "Available Beds",
                        data: [
                          10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 41, 35, 51,
                          49, 62,
                        ],
                      },
                    ]}
                    type="line"
                    //  width={"100%"}
                    height={230}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={{ span: 6 }} className="h-50">
            <div class="height-3 h-100">
              <div class="white-bg shadow-box pb-0 h-25p h-100">
                <div class="box-heading text-center mb-2">
                  4 West - RN Available Hours
                </div>
                <div class="d-flex justify-content-center mobile-wrap ">
                  <div class=" h-100 bg-white d-flex align-items-center me-5 mobile-margin-0 mobile-w-100 mobile-mb-10">
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2 h-85 px-5 mobile-w-100 mobile-text-center">
                      <h4 class="f-14 w-100 m-0">
                        13.7 - Average Available Beds
                      </h4>
                    </div>
                  </div>
                  <div
                    class=" h-100 bg-white d-flex align-items-center"
                    style={{ maxWidth: "350px" }}
                  >
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2">
                      <p class="f-14 text-center w-100 m-0">
                        Available Beds is up 66%
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <img
                          src={green}
                          height="15"
                          style={{ verticalAlign: "text-top" }}
                        />{" "}
                        &nbsp; 6.8 Average Daily Available Beds
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <small>Compared to Previous Pay Period</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="mobile-w-600 h-100 h-100-v "
                  style={{ width: "100%" }}
                >
                  <Chart
                    options={options}
                    series={[
                      {
                        name: "Available Beds",
                        data: [
                          10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 41, 35, 51,
                          49, 62,
                        ],
                      },
                    ]}
                    type="line"
                    //  width={"100%"}
                    height={230}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={{ span: 6 }} className="h-50">
            <div class="height-3 h-100">
              <div class="white-bg shadow-box pb-0 h-25p h-100">
                <div class="box-heading text-center mb-2">
                  4 West - RN Available Hours
                </div>
                <div class="d-flex justify-content-center mobile-wrap ">
                  <div class=" h-100 bg-white d-flex align-items-center me-5 mobile-margin-0 mobile-w-100 mobile-mb-10">
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2 h-85 px-5 mobile-w-100 mobile-text-center">
                      <h4 class="f-14 w-100 m-0">
                        13.7 - Average Available Beds
                      </h4>
                    </div>
                  </div>
                  <div
                    class=" h-100 bg-white d-flex align-items-center"
                    style={{ maxWidth: "350px" }}
                  >
                    <div class="d-flex align-items-center justify-content-center flex-wrap m-0 border p-2">
                      <p class="f-14 text-center w-100 m-0">
                        Available Beds is up 66%
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <img
                          src={green}
                          height="15"
                          style={{ verticalAlign: "text-top" }}
                        />{" "}
                        &nbsp; 6.8 Average Daily Available Beds
                      </p>
                      <p class="f-14 w-100 text-center m-0">
                        <small>Compared to Previous Pay Period</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="mobile-w-600 h-100 h-100-v "
                  style={{ width: "100%" }}
                >
                  <Chart
                    options={options}
                    series={[
                      {
                        name: "Available Beds",
                        data: [
                          10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 41, 35, 51,
                          49, 62,
                        ],
                      },
                    ]}
                    type="line"
                    //  width={"100%"}
                    height={230}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </PageLayout>
    </div>
  );
}

export default RealtimeTrend;
