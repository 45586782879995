import "./index.css";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { getdata } from "../../services";
import PageLayout from "../../layouts/PageLayout";
import AddModal from "../../components/ShiftModal/AddModal";
import UpdateModal from "../../components/ShiftModal/UpdateModal";
import DynamicModal from "../../components/ShiftModal/DynamicModal";
import DynamicUpdateModal from "../../components/ShiftModal/DynamicUpdateModal";
import SuccessModal from "../../components/shared/SuccessModal";
import ErrorModal from "../../components/shared/SuccessModal/ErrorModal";
import DataTable from "../../components/DataTable";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import {
  setDepartmentDetail,
  setHeadCount,
  getPreviousAndCurrentShift,
  activedatefilter,
  setPeriodType,
  setRealtimePayPeriod
} from "../../store/actions/Compensate";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";
import ContentLoader from "react-content-loader";
import { SocketContext } from "../../socket";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import { ChartExample } from "../../components/shared/PieChart/m-pei-chart";
import { ThemeContext } from "../../providers/ThemeProvider";
import BarChart from "../../components/shared/BarChart";
import BltChart from "../../components/shared/BltChart";
import PREV from "../../assets/img/prev.png";
import PREVWHITE from "../../assets/img/left-white.png";
import NEXT from "../../assets/img/next.png";
import NEXTWHITE from "../../assets/img/next-white.png";
import Trend from "../../assets/img/trend.png";
import TrendWHITE from "../../assets/img/trendwhite.png";


const MyLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader
      viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

const GraphLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader
      viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="45" />
    </ContentLoader>
  </div>
);

function Realtime(props) {
  const { urlparam } = props;
  const { id } = props;
  const [themeMode, setTheme] = useContext(ThemeContext);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const detail_data = selector.compensate.departmentdetail;
  const multichart = detail_data?.left_sidebar
    ? detail_data.left_sidebar.sidebar_1
    : {};
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const form_control = detail_data?.content
    ? detail_data.content.form_control
    : {};
  const headcount = detail_data?.header ? detail_data.header : {};
  const barchart = detail_data?.footer ? detail_data.footer : [];
  const dataTable = detail_data?.right_sidebar?.tableData
    ? detail_data?.right_sidebar.tableData
    : [];
  const getprevAndCurrentShift = selector.compensate.previousAndCurrentShift;
  const todayDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });

  const [show, setShow] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [showsucess, setShowsuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");
  const [contentId, setContent] = useState({});
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [realtimeshift, setRealtimeshift] = useState("");   
  const [filterdate, setFilterdate] = useState({});
  const [activefiltertab, setActivefiltertab] = useState("realtime");
  const [previousShiftData, setPreviousShiftData] = useState({});
  const [lastShiftData, setLastShiftData] = useState({});

  const [gridData, setGridData] = useState({});
  const [isLoader, setisLoader] = useState(true);
  const [isGraphLoader, setisGraphLoader] = useState(true);

  const [getPreAndCurr, setPreAndCurrShift] = useState({});
  const [isPressable, setIsPressable] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date(todayDate));
  const twoDaysBack = new Date(todayDate);
  twoDaysBack.setDate(new Date(todayDate).getDate() - 3);
  const [formControlData, setFormControltData] = useState({});
  const [formShiftData, setFormShiftData] = useState([]);
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );
  const realtimePayPeriod = useSelector(
    (state) => state.compensate.realtimePayPeriod
  );
  const filterDateList = useSelector(
    (state) => state.compensate.filterDateList
  );

  const costCenterApi = useSelector((state) => state.compensate.costCenterApi);

  

  window?.localStorage?.setItem("activefiltertab", "realtime");
  const handleShow = (event) => {
    setShow(true);
  };
  const callBackhide = (value, issuceess, msg) => {
    setShow(value);
    if (issuceess) {
      setShowsuccess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
    }
  };
  const handleShowUpdate = () => setShowupdate(true);
  const callBackhideupdate = (value, issuceess, msg) => {
    setShowupdate(value);
    if (issuceess) {
      setShowsuccess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
    }
  };
  const callBacksuccess = () => {};

  useEffect(() => {
    fetchDetails();
  }, [id, sliderFilterData]);

  // useEffect(() => {
  //   getBarData();
  // }, [dataTable]);

  useEffect(() => {
    socket.on("csvUpdate", (socketData) => {
      if (window?.localStorage?.getItem("activefiltertab") === "realtime") {
        fetchDetails();
      }
    });
  }, [socket]);

  let param = {
    fromdate: {
      date: moment(new Date()).format("DD-MM-YYYY"),
      shift_time: "03:00",
    },
    todate: {
      date: moment(new Date()).format("DD-MM-YYYY"),
      shift_time: "07:00",
    },
  };

  async function fetchDetails(contend_id, shifttime, navigate_date) {
    const getGraph = await getGraphControl();
    const getPreAndCurr = await getPreAndCurrShift();
    setPreAndCurrShift(getPreAndCurr);
    setFormControltData(getGraph);
    setisLoader(true);
    try {
      if (id != null) {
        if (activefiltertab === "realtime") {
          if (navigate_date) {
            param = {
              fromdate: {
                date: moment(navigate_date).format("DD-MM-YYYY"),
                shift_time: "07:00",
              },
              todate: {
                date: moment(navigate_date).format("DD-MM-YYYY"),
                shift_time: "07:00",
              },
            };
            param["cost_center_number"] = id;
          } else if (isPressable) {
            param["fromdate"] = {
              date: moment(currentDate).format("DD-MM-YYYY"),
              shift_time: "03:00",
            };
            param["todate"] = {
              date: moment(currentDate).format("DD-MM-YYYY"),
              shift_time: "23:00",
            };
            param["cost_center_number"] = id;
          } else {
            const getCurrentShift = await getPreAndCurrShift();
            param = getCurrentShift?.filterDates;
            param["cost_center_number"] = id;
          }
        } else {
          param["fromdate"] = {
            date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
            shift_time: "03:00",
          };
          param["todate"] = {
            date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
            shift_time: "23:00",
          };
          param["cost_center_number"] = id;
        }
        const resp = getdata(param);
        let data = await resp;

        setisLoader(false);
        dispatch(setHeadCount(data.data.header));
        dispatch(setDepartmentDetail(data.data));
        let defaultshift = shifttime
          ? shifttime
          : data.data?.content?.content_1?.selectedShift;
        let contentid = data.data?.content?.content_1?.id;
        setRealtimeshift(defaultshift);
        autoFillgetDirectDataById(contentid, true);
        const lastShiftEntry = data.data?.content?.lastShiftEntry;
        const lastShiftGridData = data.data?.content?.lastShiftGridData;
        setLastShiftData({ lastShiftEntry, lastShiftGridData });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getGraphControl = async () => {
    setisGraphLoader(true);
    try {
      if (id != null) {
        const paramData = {
          cost_center_number: id,
        };

        const encryptData = dataEnCrypt(paramData);
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/general/getGraphControl",
          { payloadData: encryptData }
        );
        const data = decryptedFunction(resp?.data?.responseData);
        setFormShiftData(data?.data?.graphControl);

        const convertData = async (dataArray) => {
          return dataArray?.reduce((acc, item) => {
            acc[item?.field_name] = {
              display: item?.display,
              required: item?.required,
              field_label: item?.field_label,
            };
            return acc;
          }, {});
        };

        const convertedFormData = await convertData(data?.data?.graphControl);
        setisGraphLoader(false);
        return convertedFormData;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPreAndCurrShift = async () => {
    try {
      if (id != null) {
         const paramData = {
           cost_center_number: id,
         };
        const encryptData = dataEnCrypt(paramData);
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getPreviousAndCurrentShift",
          { payloadData: encryptData });
        const data = decryptedFunction(resp?.data?.responseData);
        const result = data.data;
        dispatch(getPreviousAndCurrentShift(result));
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const autoFillgetDirectDataById = async (contentId, isdate) => {
    try {
      //console.log(contentId,"contentId")
      const fromdatearr = param.fromdate.date.split("-");
      const todatearr = param.todate.date.split("-");
      let fromdate = `${fromdatearr[1]}/${fromdatearr[0]}/${fromdatearr[2]}`;
      let todate = `${todatearr[1]}/${todatearr[0]}/${todatearr[2]}`;
      if (contentId) {
        
        const paramData = {
          id: contentId
        };
        const encryptData = dataEnCrypt(paramData);

        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { payloadData: encryptData }
        );
        const data = decryptedFunction(resp?.data?.responseData);
        // const { data } = resp;
        setPreviousShiftData(data.data.directData);
        setGridData(data.data.gridData);
        setContent({ id: contentId });
        if (isdate) {
          fromdate = `${fromdate}`;
          todate = `${todate} ${data.data.directData.shift_time}`;
          setFilterdate({ fromdate, todate });
        }
      } else {
        setPreviousShiftData([]);
        setGridData([]);
        setContent({ id: false });
        if (isdate) {
          fromdate = fromdate; //param.fromdate.shift
          todate = todate + ` ${realtimeshift}`; //param.todate.shift
          setFilterdate({ fromdate, todate });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reportnotecallback = (obj, type) => {
    //console.log(obj, 'objobjobjobj');
    dispatch(activedatefilter(obj));
    if (type === 1) {
      autoFillgetDirectDataById(obj.id, false);
      handleShowUpdate();
    } else {
      autoFillgetDirectDataById(obj._id, false);
      handleShow();
    }

    //fetchDetails(id, shift);
  };

  const addupdatereportnotecallback = (id) => {
    fetchDetails(id, null);
  };

   const processChartData = (dataKey) => {
    let linechartseries = []
    if(costCenterData?.interval === 24) {
        const firstItem = dataTable?.[0] || {}; 
         firstItem &&
           linechartseries.push({
             shift_time: firstItem?.shift_date_md,
             value: +firstItem?.[dataKey],
           });
    } else {
          linechartseries = dataTable?.map((item) => ({
           shift_time: item.shift_time,
           value: +item[dataKey],
         }));
    }
     const allZeros = linechartseries.every((obj) => +obj.value === 0);
     return allZeros
       ? linechartseries.map((obj) => ({ ...obj, value: undefined }))
       : linechartseries;
   };

   const processDidBlt = (dataKey) => {
    let didBltseries = [];
      if (costCenterData?.interval === 24) {
        const firstItem = dataTable?.[0] || {};
        firstItem &&
          didBltseries.push({
            shift_time: firstItem?.shift_date_md,
            value:
              firstItem[dataKey] !== null
                ? firstItem[dataKey]
                  ? 1
                  : 1.01
                : null,
          });
      } else {
        didBltseries = dataTable?.map((item) => ({
          shift_time: item.shift_time,
          value: item[dataKey] !== null ? (item[dataKey] ? 1 : 1.01) : null,
        }));
      }
     return didBltseries;
   };

  const handlePreviousClick = () => {
    const oneDayBack = new Date(currentDate);
    oneDayBack.setDate(currentDate.getDate() - 1);
    fetchDetails(id, null, oneDayBack);
    setCurrentDate(oneDayBack);
    setIsPressable(true);
  };

  const handleNextClick = () => {
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    fetchDetails(id, null, tomorrow);
    setIsPressable(true);
    if (tomorrow > new Date()) {
      return;
    }
    setCurrentDate(tomorrow);
  };

  const handleLink = () => {
    setIsPressable(false);
    fetchDetails(id, null, todayDate);
    setCurrentDate(new Date(todayDate));
  };

  return (
    <PageLayout
      data={headcount}
      issearch={false}
      isshow={true}
      isLoader={isLoader}
      callbackfunc={reportnotecallback}
      isCallback={false}
      cost_center_number={id}
      filterdate={filterdate.fromdate}
    >
      {costCenterApi.length > 0 ? (
        <div className="row mt-2 gx-2  mb-2 department-details-page rt-v">
          <div className="col-md-12 position-relative">
            {currentDate >= twoDaysBack && (
              <button
                className="border-0 prev"
                onClick={() => {
                  handlePreviousClick();
                }}
              >
                <img src={themeMode.theme === "DARK" ? PREVWHITE : PREV} />
              </button>
            )}
            {currentDate.toDateString() !==
              new Date(todayDate).toDateString() && (
              <button
                className="border-0 next"
                onClick={() => {
                  handleNextClick();
                }}
              >
                <img src={themeMode.theme === "DARK" ? NEXTWHITE : NEXT} />
              </button>
            )}
            <div className="row gx-2 h-100">
              <div className="col-md-3  width-22">
                <div className="white-bg shadow-box h-100 1111 details overflow-hidden">
                  <div class="box-heading text-center">
                    {/* Completion and Productivity Status */}
                    {costCenterData?.interval === 24
                      ? "Productivity "
                      : "Completion and Productivity Status"}
                  </div>
                  {Object.keys(multichart).length > 0 && !isLoader ? (
                    <ChartExample
                      data={detail_data.left_sidebar?.sidebar_1}
                      callbackfunc={reportnotecallback}
                      data1={multichart}
                      isdetail={true}
                      directId={id}
                      showCompilanceRing={costCenterData?.show_compliance_ring}
                      showProductivityRing={
                        costCenterData?.show_productivity_ring
                      }
                      isRealtimeToday={detail_data?.header?.isRealTime}
                    />
                  ) : (
                    <MyLoader themeMode={themeMode} />
                  )}
                  <div className="d-flex text-center flex-wrap">
                    <Link
                      className={`flex-grow-1 me-1 border-btn f-14 cursor-pointer w-100 ${
                        currentDate.toDateString() ===
                          new Date(todayDate).toDateString() && "green-b active"
                      }`}
                      to={`/realtime/department-details/${urlparam}/realtime`}
                      onClick={() => {
                        handleLink();
                      }}
                    >
                      RealTime
                    </Link>
                    <Link
                      className={
                        "flex-grow-1 me-1 border-btn f-14 cursor-pointer green-b"
                      }
                      to={`/realtime/department-details/${urlparam}/currentperiod`}
                      onClick={() => {
                        dispatch(setPeriodType(filterDateList?.[0]));
                        dispatch(
                          setRealtimePayPeriod(filterDateList?.[0]?.value)
                        );
                      }}
                    >
                      Current Period
                    </Link>
                    <Link
                      className={
                        "flex-grow-1 border-btn f-14 cursor-pointer green-b"
                      }
                      to={`/realtime/department-details/${urlparam}/previousperiods`}
                      onClick={() => {
                        dispatch(setPeriodType(filterDateList?.[1]));
                        dispatch(
                          setRealtimePayPeriod(filterDateList?.[1]?.value)
                        );
                      }}
                    >
                      Previous Periods
                    </Link>
                  </div>
                  <div>
                    {Object.keys(filterdate).length > 0 ? (
                      <div className="col-md-12 mt-3">
                        <div className="recordshowing text-center">
                          {filterdate.fromdate}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-6 width-53">
                <div className="h-100">
                  <DataTable
                    cost_center_name={multichart.cost_center_name}
                    callbackfunc={reportnotecallback}
                    directId={contentId}
                    realtimeshift={realtimeshift}
                    isLoader={isLoader}
                    data={dataTable}
                    cost_center_number={id}
                    realtimefunc={fetchDetails}
                  />
                </div>
              </div>
              <div class="col-md-3 plr-4 width-22">
                <div class="white-bg shadow-box pb-0 h-25p h-100">
                  <div class="box-heading text-center mb-2">Workdrivers 
                    &nbsp;
                  {process.env.React_App_ENVIRONMENT !== "production" && 
                  <img src={themeMode.theme === "DARK" ? TrendWHITE : Trend}  width={17} style={{verticalAlign: "bottom"}}/>
                  }
                  </div>
                  {formShiftData
                    ?.sort((a, b) => a.display_graph - b.display_graph)
                    ?.map((val, ind) => {
                      return (
                        <div key={ind} class="row mt-">
                          <div class="col-md-12">
                            <div
                              class="text-start white-text-dark-mode f-13 font-w-500"
                              style={{
                                color:
                                  "themeMode.theme === 'DARK' ? 'white' : 'black',",
                              }}
                            >
                              {val?.field_label}
                            </div>
                            {val?.field_name === "did_blt_cover_obs" ? (
                              <>
                                <BltChart
                                  barchartseries={processDidBlt(
                                    val?.field_name
                                  )}
                                  barchartcat={[]}
                                  toolData={val?.field_label}
                                  color={"#458FFF"}
                                  isLoader={isLoader}
                                  // isRealtime24={
                                  //   costCenterData?.interval === 24 ? true : false
                                  // }
                                />
                              </>
                            ) : (
                              <BarChart
                                barchartseries={processChartData(
                                  val?.field_name
                                )}
                                barchartcat={[]}
                                toolData={val?.field_label}
                                color={"#458FFF"}
                                isLoader={isLoader}
                                // isRealtime24={
                                //   costCenterData?.interval === 24 ? true : false
                                // }
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-50">
          <p className="text-secondary display-6">No Data Found</p>
        </div>
      )}

      {/* Add Modals */}
      {form_control ? (
        <DynamicModal
          isShow={show}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          callbackfunc={callBackhide}
          contentId={contentId}
          previousShiftData={lastShiftData.lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          addgridData={lastShiftData.lastShiftGridData}
          reloaddata={addupdatereportnotecallback}
          cost_center_number={id}
          getPreAndCurr={getPreAndCurr}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
          formContent={multichart}
        />
      ) : (
        <AddModal
          isShow={show}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          callbackfunc={callBackhide}
          contentId={contentId}
          previousShiftData={lastShiftData.lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          addgridData={lastShiftData.lastShiftGridData}
          reloaddata={addupdatereportnotecallback}
          cost_center_number={id}
          getPreAndCurr={getPreAndCurr}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
        />
      )}

      {/* Update Modals */}

      {form_control ? (
        contentone.id !== undefined ? (
          <DynamicUpdateModal
            isShow={showupdate}
            cost_center_name={multichart.cost_center_name}
            numbers={headcount?.box3?.total_rn_variance}
            previousShiftData={previousShiftData}
            getprevAndCurrentShift={getprevAndCurrentShift}
            gridData={gridData}
            callbackfunc={callBackhideupdate}
            reloaddata={addupdatereportnotecallback}
            data_id={contentId}
            cost_center_number={id}
            getPreAndCurr={getPreAndCurr}
            acuityNurse={multichart?.accepted_acuity_per_nurse}
            formContent={multichart}
          />
        ) : null
      ) : contentone.id !== undefined ? (
        <UpdateModal
          isShow={showupdate}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          previousShiftData={previousShiftData}
          getprevAndCurrentShift={getprevAndCurrentShift}
          gridData={gridData}
          callbackfunc={callBackhideupdate}
          reloaddata={addupdatereportnotecallback}
          data_id={contentId}
          cost_center_number={id}
          getPreAndCurr={getPreAndCurr}
          acuityNurse={multichart?.accepted_acuity_per_nurse}
        />
      ) : null}

      <SuccessModal
        isShow={showsucess}
        callbackfunc={callBacksuccess}
        title="Success"
        content={sucessmsg}
      />
      <ErrorModal
        isShow={showError}
        callbackfunc={callBacksuccess}
        title="Error"
        content={sucessmsg}
      />
    </PageLayout>
  );
}

export default Realtime;
